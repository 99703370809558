import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { MultiDataSet, Label } from 'ng2-charts';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FondsRepartition, MandatGestion, MandatGestion2, ModeGestionRecommandation, Poche, Recommandation } from '@webapi/MIF.Subscription.Parrot';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { LabelsDataService } from '@services/labels-data.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { constants } from '@constants/constants';
import {
  getCurrentProductType,
  getCurrentProductTypeId,
  getDefaultRecommendationTabPerProduct,
  getIsOverwritedToGH,
  getIsOverwritedToGL,
  getIsOverwritedToGSM,
  getProfileColor,
  getRecommendationTabNameById,
  isCems,
  isCims,
  isPeri,
  isQuestion12AnsweredYes,
  openFileInNewTab
} from '@extensions/extensions';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { DefaultProductTabs, ProductType, Profile } from '@webapi/MIF.Subscription.WebApi';
import { ContratTransferableFourgousExtended } from '@webapi/services/ws-polices.service';
import { Router } from '@angular/router';
import { CustomerService } from '@webapi/services/customer.service';
import { FundsService } from '@my-contract/funds.service';
import { ProfileStepName } from '@models/profile-step-name';
import { PocheEnum } from '@models/poche';
import { MinAmountPerMode } from '@models/min-amount-per-mode';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { PerformanceProductType } from '@webapi/services/portfolio.service';
import { SettingsService } from '@webapi/services/settings.service';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss']
})
export class ProfileSummaryComponent implements OnInit {
  @Input() recommandation: Recommandation;
  @Input() mandatGestion: MandatGestion2;
  @Input() questions: any;
  @Input() clientProfile: Profile;
  @Input() transferableContracts: ContratTransferableFourgousExtended[];
  public onTabChanged: EventEmitter<string> = new EventEmitter();

  isPeri: boolean = isPeri();
  isCims: boolean = isCims();
  isCems: boolean = isCems();
  productType: PerformanceProductType = isPeri() ? PerformanceProductType.PERI : PerformanceProductType.CEMS_CIMS;
  currentProductType = getCurrentProductType();
  currentProductTypeId = getCurrentProductTypeId();
  ProfileStepName = ProfileStepName;
  constants: any = constants;
  chartsOptions = { cutoutPercentage: 85 };
  blueColor = '#009ad5';
  fondEuroText = 'Fonds en euros MIF';
  fondOthersText = 'Unités de compte';

  prudenteTooltipText = `<img src='assets/img/peri-prudent.jpg' alt=''/>`;
  equilibreeTooltipText = `<img src='assets/img/peri-balanced.jpg' alt=''/>`;
  dynamiqueTooltipText = `<img src='assets/img/peri-dynamic.jpg' alt=''/>`;

  activeGestionLibreTab: boolean = true;
  activeGestionSousMandatTab: boolean;
  activeGestionHorizonTab: boolean;

  faInfoCircle = faInfoCircle;
  chartLabels: Label[];
  chartPercentages: MultiDataSet;
  chartColors: any;

  gestionLibre: ModeGestionRecommandation;
  gestionSousMandat: ModeGestionRecommandation;
  gestionHorizon: ModeGestionRecommandation;

  selectedMandatGestion: MandatGestion;
  fondsExpanded = false;
  expandedFonds: ExpandedFond[];
  expandedSereniteFonds: ExpandedFond[];
  calculatedPercentScore: number;
  chartPercentagesExpanded: any[];
  chartColorsExpanded: any[];
  chartLabelsExpanded: any[];
  currentPlanInitialPercentages: MultiDataSet;
  amount: number;
  minAmountPerMode: MinAmountPerMode;
  securityOtherFond: FondsRepartition;
  profileTypeCode: number;
  managementTypeCode: number;
  selectedPlanName: string;
  profileTypeRecommended: any;
  selectedInvesmentProfile: number;
  offersCount: number = 0;
  clientProfileForViewOffer: Profile;
  sereniteFondsExpanded: boolean;
  sumSelectedMondatFondsPercentages: number;
  gestionHorizonMandatGestion: ModeGestionRecommandation;
  defaultProductTabsConfiguration: DefaultProductTabs;

  constructor(
    private labelsDataService: LabelsDataService,
    private bsModalService: BsModalService,
    private customerService: CustomerService,
    private wsReferentielMetierService: WsReferentielMetierService,
    private router: Router,
    private fundsService: FundsService,
    public labelTextPipe: LabelTextPipe,
    private settingsService: SettingsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchConnfig();
    this.getAvailableModes();
    this.initCurrentRecommendation();
    this.selectedMandatGestion = Object.assign(this.mandatGestion ?? {});
    this.fetchAllPlansData(); // do not waiting user click and reusing data from local service storage after
    await this.fetchInitData();
    this.clientProfileForViewOffer = Object.assign(new Profile(), this.clientProfile);
    this.recalculateClientProfileDataForViewOffer();
  }

  async fetchInitData(): Promise<void> {
    this.minAmountPerMode = await this.wsReferentielMetierService.getMinAmountPerMode();
  }

  fetchAllPlansData(): void {
    // to speed up ui response when user changes the plan
    this.wsReferentielMetierService.getMandatGestion(constants.prudent.id);
    this.wsReferentielMetierService.getMandatGestion(constants.equilibré.id);
    this.wsReferentielMetierService.getMandatGestion(constants.dynamique.id);
    this.wsReferentielMetierService.getMandatGestion(constants.offensif.id);
  }

  updateCharts(labels: Label[], percentages: MultiDataSet, collors: any[]): void {
    this.chartLabels = labels;
    this.chartPercentages = percentages;
    this.chartColors = collors;
  }

  async fetchConnfig(): Promise<void> {
    this.defaultProductTabsConfiguration = await this.settingsService.getDefaultProductTabsConfiguration();
  }

  getAvailableModes(): void {
    this.recommandation.produitRecommande.modeGestion.forEach((mode: ModeGestionRecommandation) => {
      if (mode.libelle == constants.gestionHorizon.name) {
        this.gestionHorizon = mode;
        return;
      }
      if (mode.libelle == constants.gestionLibre.name) {
        this.gestionLibre = mode;
        return;
      }
      if (mode.libelle == constants.gestionSousMandat.name) {
        this.gestionSousMandat = mode;
        return;
      }
    });

    this.processTabSelection();
  }

  processTabSelection() {
    let isOverrided = this.processOverridingTabSelection();

    if (isOverrided === false) {
      switch (this.currentProductTypeId) {
        case ProductType.Cems: {
          const defaultTabCems: number = getDefaultRecommendationTabPerProduct(this.defaultProductTabsConfiguration, constants.productNames.cems.id);
          this.changeTab(getRecommendationTabNameById(defaultTabCems));
          break;
        }
        case ProductType.Cims: {
          const defaultTabCims: number = getDefaultRecommendationTabPerProduct(this.defaultProductTabsConfiguration, constants.productNames.cims.id);
          this.changeTab(getRecommendationTabNameById(defaultTabCims));
          break;
        }
        case ProductType.Peri: {
          const defaultTabPeri: number = getDefaultRecommendationTabPerProduct(this.defaultProductTabsConfiguration, constants.productNames.peri.id);
          this.changeTab(getRecommendationTabNameById(defaultTabPeri));
          break;
        }
      }
    }
  }

  processOverridingTabSelection(): boolean {
    const isOverwritedToGL = getIsOverwritedToGL(this.recommandation?.profil?.id);
    const isOverwritedToGH = getIsOverwritedToGH(this.recommandation?.profil?.id);
    const isOverwritedToGSM = getIsOverwritedToGSM(this.recommandation?.profil?.id);
    let isOverrided = false;

    if (this.isPeri && this.gestionHorizon && isOverwritedToGH) {
      isOverrided = true;
      this.changeTab(constants.gestionHorizon.name);
    } else if (this.gestionLibre && isOverwritedToGL) {
      isOverrided = true;
      this.changeTab(constants.gestionLibre.name);
    } else if (this.gestionSousMandat && isOverwritedToGSM) {
      isOverrided = true;
      this.changeTab(constants.gestionSousMandat.name);
    }

    return isOverrided;
  }

  get isQuestion12AnsweredYes(): boolean {
    return isQuestion12AnsweredYes(this.questions, this.clientProfile);
  }

  get isOneMandat(): boolean {
    return this.recommandation.produitRecommande.modeGestion.length === 1;
  }

  get mandatGestionId(): number {
    return this.recommandation.id;
  }

  get GHSereniteTitle(): string {
    const gHModeGestion = this.gestionHorizonMandatGestion;

    const serenitePoche = gHModeGestion?.poche.filter((poche: Poche) => {
      return poche.libelle === constants.periPoches.serenite.name;
    })[0];

    return `${serenitePoche?.repartition} ${this.labelTextPipe.transform('Recommendation.PocheExtraText1')}`;
  }

  get GHSereniteLabel(): string {
    const gHModeGestion = this.gestionHorizonMandatGestion;

    const serenitePoche = gHModeGestion?.poche.filter((poche: Poche) => {
      return poche.libelle === constants.periPoches.serenite.name;
    })[0];

    return serenitePoche?.libelle;
  }

  get GHPerformanceTitle(): string {
    const gHModeGestion = this.gestionHorizonMandatGestion;

    const performancePoche = gHModeGestion?.poche.filter((poche: Poche) => {
      return poche.libelle === constants.periPoches.performance.name;
    })[0];

    return `${performancePoche?.repartition} ${this.labelTextPipe.transform('Recommendation.PocheExtraText2')}`;
  }

  get GHPerformanceLabel(): string {
    const gHModeGestion = this.gestionHorizonMandatGestion;

    const performancePoche = gHModeGestion?.poche.filter((poche: Poche) => {
      return poche.libelle === constants.periPoches.performance.name;
    })[0];

    return performancePoche?.libelle;
  }

  get GSMEuroFondsRepartition(): FondsRepartition {
    return this.selectedMandatGestion.allocation?.find((rep: FondsRepartition) => {
      return rep.fonds?.id === 1;
    });
  }

  get GSMEuroTitle(): string {
    return this.GSMEuroFondsRepartition?.titre;
  }

  get GSMEuroLabel(): string {
    return this.GSMEuroFondsRepartition?.libelle;
  }

  get GSMUCDistribution(): number {
    return 100 - this.GSMEuroFondsRepartition?.repartition;
  }

  get GLEuroTitle(): string {
    const GLModeGestion = this.recommandation.produitRecommande.modeGestion.filter((mode: ModeGestionRecommandation) => {
      return mode.libelle === constants.gestionLibre.name;
    })[0];

    const pocheEuros = GLModeGestion?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.EURO;
    })[0];

    const euroFondsRepartition = pocheEuros.fondsRepartition[0];

    return euroFondsRepartition.titre;
  }

  get GLEuroLabel(): string {
    const GLModeGestion = this.recommandation.produitRecommande.modeGestion.filter((mode: ModeGestionRecommandation) => {
      return mode.libelle === constants.gestionLibre.name;
    })[0];

    const pocheEuros = GLModeGestion?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.EURO;
    })[0];

    const euroFondsRepartition = pocheEuros.fondsRepartition[0];

    return euroFondsRepartition.libelle;
  }

  get GLUCTitle(): string {
    const GLModeGestion = this.recommandation.produitRecommande.modeGestion.filter((mode: ModeGestionRecommandation) => {
      return mode.libelle === constants.gestionLibre.name;
    })[0];

    const pocheUC = GLModeGestion?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.UC;
    })[0];

    const notEuroFondsRepartition = pocheUC?.fondsRepartition[0];

    return notEuroFondsRepartition?.titre;
  }

  get GLUCLabel(): string {
    const GLModeGestion = this.recommandation.produitRecommande.modeGestion.filter((mode: ModeGestionRecommandation) => {
      return mode.libelle === constants.gestionLibre.name;
    })[0];

    const pocheUC = GLModeGestion?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.UC;
    })[0];

    const notEuroFondsRepartition = pocheUC?.fondsRepartition[0];

    return notEuroFondsRepartition?.libelle;
  }

  initCurrentRecommendation(name?: string): void {
    this.selectedPlanName = name ? name : this.recommandation.profil.libelle;
    this.profileTypeRecommended = Object.assign({}, { plan: this.recommandation.profil.libelle });

    switch (this.selectedPlanName) {
      case constants.sécuritaire.name: {
        this.switchIfSécuritaire();
        break;
      }
      case constants.prudent.name: {
        this.switchIfPrudente();
        break;
      }
      case constants.equilibré.name: {
        this.switchIfEquilibree();
        break;
      }
      case constants.dynamique.name: {
        this.switchIfDynamique();
        break;
      }
      case constants.offensif.name: {
        this.switchIfOffensive();
        break;
      }
      default:
        break;
    }
  }

  get profileColor(): string {
    return getProfileColor(this.recommandation.profil.libelle);
  }

  get securityProfileColor(): string {
    return constants.sécuritaireOtherColor;
  }

  showConditionsModal(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        body: this.labelsDataService.getData('details.contactsRedBoxLinkContent')
      },
      class: 'modal-lg'
    });
  }

  changeTab(profileName: string): void {
    switch (profileName) {
      case constants.gestionHorizon.name: {
        if (this.gestionHorizon) {
          this.activeGestionHorizonTab = true;
          this.activeGestionSousMandatTab = false;
          this.activeGestionLibreTab = false;
          this.managementTypeCode = constants.gestionHorizon.id;
          this.onTabChanged.next(profileName);
        } else {
          this.selectGLTab(); // for case no such tab by recommendation
        }
        break;
      }
      case constants.gestionSousMandat.name: {
        if (this.gestionSousMandat) {
          this.activeGestionSousMandatTab = true;
          this.activeGestionLibreTab = false;
          this.activeGestionHorizonTab = false;
          this.managementTypeCode = constants.gestionSousMandat.id;
          this.onTabChanged.next(profileName);
        } else {
          this.selectGLTab();
        }
        break;
      }
      case constants.gestionLibre.name: {
        if (this.gestionLibre) {
          this.activeGestionLibreTab = true;
          this.activeGestionSousMandatTab = false;
          this.activeGestionHorizonTab = false;
          this.managementTypeCode = constants.gestionLibre.id;
          this.onTabChanged.next(profileName);
        } else {
          this.selectGLTab();
        }
        break;
      }
      default: {
        this.selectGLTab();
      }
    }

    this.initCurrentRecommendation();
    this.collapseFonds();
  }

  selectGLTab(): void {
    this.activeGestionLibreTab = true;
    this.activeGestionSousMandatTab = false;
    this.activeGestionHorizonTab = false;
    this.managementTypeCode = constants.gestionLibre.id;
    this.onTabChanged.next(constants.recommendationTabs.gestionLibre.name);
  }

  getDefaultChartColors(isSecurity: boolean = false): any {
    return [
      {
        backgroundColor: [this.blueColor, isSecurity ? constants.sécuritaireOtherColor : this.profileColor]
      }
    ];
  }

  getSecurityChartColors(): any {
    return [
      {
        backgroundColor: [this.blueColor]
      }
    ];
  }

  initExpandedFonds(): void {
    this.expandedFonds = [];
    this.chartLabelsExpanded = [];
    this.chartPercentagesExpanded = [];
    this.chartColorsExpanded = [
      {
        backgroundColor: []
      }
    ];
    this.calculatedPercentScore = 0;

    if (this.activeGestionHorizonTab) {
      this.gestionHorizonMandatGestion?.poche
        .find((poche: Poche) => {
          return poche.libelle === PocheEnum.PPerf;
        })
        ?.fondsRepartition.forEach((item: FondsRepartition) => {
          const label = item.titre;
          this.chartPercentagesExpanded.push(item.repartition);
          this.chartColorsExpanded[0].backgroundColor.push(this.profileColor);
          this.chartLabelsExpanded.push(label);
          this.calculatedPercentScore += item.repartition;
          this.expandedFonds.push({
            id: item.fonds.id,
            title: label,
            isEuro: false,
            file: item.fonds.document[0].fichierAttache,
            description: item.libelle
          } as ExpandedFond);
        });
    } else {
      this.selectedMandatGestion.allocation.forEach((item: FondsRepartition) => {
        if (item.fonds.typeFonds.id === 1) {
          const label = `${this.currentPlanInitialPercentages[0][0]}${this.labelTextPipe.transform('Recommendation.PercentageInvestedOnFundText')}`;
          this.chartPercentagesExpanded.push(this.currentPlanInitialPercentages[0][0]);
          this.chartColorsExpanded[0].backgroundColor.push(this.blueColor);
          this.chartLabelsExpanded.push(label);
          this.calculatedPercentScore += this.currentPlanInitialPercentages[0][0] as number;
          this.expandedFonds.push({
            id: item.fonds.id,
            title: label,
            isEuro: true,
            description: item.libelle
          } as ExpandedFond);
        } else {
          const label = item.titre;
          this.chartPercentagesExpanded.push(item.repartition);
          this.chartColorsExpanded[0].backgroundColor.push(this.profileColor);
          this.chartLabelsExpanded.push(label);
          this.calculatedPercentScore += item.repartition;
          this.expandedFonds.push({
            id: item.fonds.id,
            title: label,
            isEuro: false,
            file: item.fonds.document[0].fichierAttache,
            description: item.libelle
          } as ExpandedFond);
        }
      });

      this.moveEuroFontToTheTopOfExpandedList();
    }

    this.updateCharts(this.chartLabelsExpanded, this.chartPercentagesExpanded, this.chartColorsExpanded);
  }

  moveEuroFontToTheTopOfExpandedList(): void {
    this.expandedFonds.sort(x => (x.isEuro ? -1 : 1));
  }

  expandSereniteFonds(): void {
    this.sereniteFondsExpanded = true;
    this.initSereniteExpandedFonds();
  }

  initSereniteExpandedFonds() {
    this.expandedSereniteFonds = [];
    this.chartLabelsExpanded = [];
    this.chartPercentagesExpanded = [];
    this.chartColorsExpanded = [
      {
        backgroundColor: []
      }
    ];
    this.calculatedPercentScore = 0;

    this.gestionHorizonMandatGestion?.poche
      .find((poche: Poche) => {
        return poche.libelle === PocheEnum.PSer;
      })
      ?.fondsRepartition.forEach((item: FondsRepartition) => {
        const label = item.titre ?? '';
        this.chartPercentagesExpanded.push(item.repartition);
        this.chartLabelsExpanded.push(label);
        this.calculatedPercentScore += this.currentPlanInitialPercentages[0][0] as number;
        // this.chartColorsExpanded[0].backgroundColor.push(item.fonds.id === 1 ? this.blueColor : this.profileColor);
        this.chartColorsExpanded[0].backgroundColor.push(this.blueColor);
        this.expandedSereniteFonds.push({
          id: item.fonds.id,
          title: item.titre,
          //isEuro: item.fonds.id === 1,
          isEuro: true,
          description: item.libelle ?? ''
        } as ExpandedFond);
      });

    this.updateCharts(this.chartLabelsExpanded, this.chartPercentagesExpanded, this.chartColorsExpanded);
  }

  collapseSereniteFonds(): void {
    // todo: refactor by data from api
    this.sereniteFondsExpanded = false;
    this.chartLabels = this.getChartLabels();
    this.chartColors = this.getDefaultChartColors();
    this.updateCharts(this.chartLabels, this.currentPlanInitialPercentages, this.chartColors);
  }

  expandFonds(): void {
    this.fondsExpanded = true;
    this.initExpandedFonds();
  }

  collapseFonds(): void {
    this.fondsExpanded = false;
    this.chartLabels = this.getChartLabels();
    this.chartColors = this.getDefaultChartColors();
    this.updateCharts(this.chartLabels, this.currentPlanInitialPercentages, this.chartColors);
  }

  async getSelectedMandatManual(): Promise<void> {
    const doc = await this.wsReferentielMetierService.getDocumentsFichier(parseInt(this.selectedMandatGestion.document.id, 10));

    if (doc) {
      await openFileInNewTab(doc.fichierAttache);
    }
  }

  getExpandedFondsColor(item: ExpandedFond): string {
    return item.isEuro ? this.blueColor : this.profileColor;
  }

  async getMandatManualByFondItem(item: ExpandedFond): Promise<void> {
    if (item.isEuro) {
      await this.getMandatManualById(1);
    } else if (item.id) {
      const fileBlob = await this.fundsService.getFundRegulatoryDocument(item.id, item.title);
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    }
  }

  async getMandatManualById(id: number): Promise<void> {
    let fond;

    const fonds = this.selectedMandatGestion.allocation.filter((item: FondsRepartition) => {
      return item.fonds.id === id;
    });

    if (fonds && fonds.length > 0) {
      fond = fonds[0].fonds;

      const fileBlob = await this.fundsService.getFundRegulatoryDocument(fond.id, fond.libelle);
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    }
  }

  async getSecuritaireMandatManualEuroById(id: number): Promise<void> {
    let fond;

    const GL = this.recommandation.produitRecommande.modeGestion.find((mode: ModeGestionRecommandation) => {
      return mode.id === constants.gestionLibre.id;
    });

    const euroPoche = GL.poche.find((poche: Poche) => {
      return poche.libelle === PocheEnum.EURO;
    });

    const fonds = euroPoche.fondsRepartition.filter((item: FondsRepartition) => {
      return item.fonds.id === id;
    });

    if (fonds && fonds.length > 0) {
      fond = fonds[0].fonds;

      const fileBlob = await this.fundsService.getFundRegulatoryDocument(fond.id, fond.libelle);
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    }
  }

  async getSecuritaireMandatManualUc(): Promise<void> {
    // Securitaire 75/25
    let fond;

    const GL = this.recommandation.produitRecommande.modeGestion.find((mode: ModeGestionRecommandation) => {
      return mode.id === constants.gestionLibre.id;
    });

    const euroPoche = GL.poche.find((poche: Poche) => {
      return poche.libelle === PocheEnum.UC;
    });

    const fonds = euroPoche.fondsRepartition.filter((item: FondsRepartition) => {
      return item.fonds;
    });

    if (fonds && fonds.length > 0) {
      fond = fonds[0].fonds;

      const fileBlob = await this.fundsService.getFundRegulatoryDocument(fond.id, fond.libelle);
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    }
  }

  onOffersCountChanged(count: number) {
    this.offersCount = count;
  }

  async getGestionLibreCprDoc(): Promise<void> {
    const ucPoche = this.gestionLibre.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.UC;
    })[0];

    const fonds = ucPoche?.fondsRepartition.filter((item: FondsRepartition) => {
      return item.fonds.id !== 1;
    });

    if (fonds && fonds.length > 0) {
      const files = fonds[0].fonds.document?.filter((doc: any) => {
        return doc?.type?.id === constants.docTypeIdToRetriveFromRecommendation;
      });

      if (files?.length > 0) {
        await openFileInNewTab(files[0].fichierAttache);
      }
    }
  }

  recalculateClientProfileDataForViewOffer(): void {
    const isRecommendationsFollowed = this.selectedPlanName === this.profileTypeRecommended.plan;
    const allocations = this.getAllocations(this.managementTypeCode);
    const investmentProfile = {
      recommendedProfileId: this.recommandation.profil.id,
      selectedProfileId: this.selectedInvesmentProfile,
      managementTypeCode: this.managementTypeCode
    } as InvestmentProfile;

    this.clientProfileForViewOffer = this.customerService.updateFondsModelWithoutSaving(this.clientProfile, allocations, investmentProfile, this.profileTypeCode, isRecommendationsFollowed);
  }

  getChartPercentages(): MultiDataSet {
    this.sumSelectedMondatFondsPercentages = 0;

    if (this.activeGestionHorizonTab) {
      if (!this.gestionHorizonMandatGestion) {
        return [[], []];
      }

      const pocheSer = this.gestionHorizonMandatGestion.poche.find((poche: Poche) => {
        return poche.libelle === PocheEnum.PSer;
      });
      const pochePerf = this.gestionHorizonMandatGestion.poche.find((poche: Poche) => {
        return poche.libelle === PocheEnum.PPerf;
      });

      return [[+pocheSer.repartition, +pochePerf.repartition]];
    } else {
      this.selectedMandatGestion.allocation
        .filter((item: FondsRepartition) => {
          return item.fonds.id !== 1;
        })
        .forEach((item: FondsRepartition) => {
          this.sumSelectedMondatFondsPercentages += item.repartition;
        });

      return [[100 - this.sumSelectedMondatFondsPercentages, this.sumSelectedMondatFondsPercentages]];
    }
  }

  getChartLabels(): Label[] {
    if (this.activeGestionHorizonTab) {
      return [PocheEnum.PSer, PocheEnum.PPerf];
    } else {
      return [this.fondEuroText, this.fondOthersText];
    }
  }

  getSecurityChartPercentages(): MultiDataSet {
    let notEuroPercentages = 0;

    if (this.recommandation.id === constants.securitaireProfileLess40KId || this.recommandation.id === constants.securitaireProfileCims) {
      return [[100]];
    }

    notEuroPercentages += +this.recommandation.produitRecommande.modeGestion[0]?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.UC;
    })[0]?.repartition;

    return [[100 - notEuroPercentages, notEuroPercentages]];
  }

  async switchIfSécuritaire(): Promise<void> {
    if (this.recommandation.id === constants.securitaireProfileMore40KId) {
      this.securityOtherFond = this.recommandation.produitRecommande.modeGestion[0]?.poche.filter((item: Poche) => {
        return item.libelle === PocheEnum.UC;
      })[0]?.fondsRepartition[0];
    }

    this.selectedInvesmentProfile = null;
    this.profileTypeCode = this.recommandation.id;
    this.selectedPlanName = constants.sécuritaire.name;
    this.chartPercentages = this.getSecurityChartPercentages();
    this.currentPlanInitialPercentages = Object.assign({}, this.chartPercentages);
    this.chartLabels = this.recommandation.id === constants.securitaireProfileLess40KId ? [this.fondEuroText] : [this.fondEuroText, this.securityOtherFond?.fonds.libelle];
    this.chartColors = this.recommandation.id === constants.securitaireProfileLess40KId ? this.getSecurityChartColors() : this.getDefaultChartColors(true);
    this.updateCharts(this.chartLabels, this.chartPercentages, this.chartColors);
    this.recalculateClientProfileDataForViewOffer();
  }

  async switchIfPrudente(): Promise<void> {
    if (this.activeGestionHorizonTab) {
      if (this.sereniteFondsExpanded) {
        this.sereniteFondsExpanded = false;
      }
      const montantVerse = this.clientProfile.initialPaymentAmount || 0; // initialPaymentAmount - it's one field for free payment amount and first payment amount
      const dateEstimationDepartRetraite = this.isPeri ? this.clientProfile.retirementPlan.expectedRetirementDate : null;
      const flagDurabilite = this.clientProfile.isQuestion12AnsweredYes(this.questions);
      const recommandationResponse = await this.wsReferentielMetierService.getRecommandation(
        24,
        !!this.clientProfile.transferContractCode,
        montantVerse,
        false,
        dateEstimationDepartRetraite,
        flagDurabilite
      );
      this.gestionHorizonMandatGestion = recommandationResponse.recommandation.produitRecommande.modeGestion.find((item: ModeGestionRecommandation) => {
        return item.libelle === constants.gestionHorizon.name;
      });
      this.selectedMandatGestion = new MandatGestion2({ ...this.gestionHorizonMandatGestion.mandatGestion });
      this.selectedMandatGestion.allocation = [];
      this.gestionHorizonMandatGestion.poche
        .map((item: Poche) => {
          return item.fondsRepartition;
        })
        .forEach((frs: FondsRepartition[]) => {
          frs?.forEach((fr: FondsRepartition) => {
            this.selectedMandatGestion.allocation.push(fr);
          });
        });
    } else {
      const allMondatGestions = await (await this.wsReferentielMetierService.getMandatGestion(constants.prudent.id)).mandatGestion;
      allMondatGestions.forEach((item: MandatGestion2) => {
        if (item.id === constants.prudent.id) {
          this.selectedMandatGestion = item;
        }
      });
    }

    this.selectedInvesmentProfile = constants.prudent.id;
    this.profileTypeCode = constants.prudent.id;
    this.selectedPlanName = constants.prudent.name;
    this.chartPercentages = this.getChartPercentages();
    this.currentPlanInitialPercentages = this.getChartPercentages();
    this.chartLabels = this.getChartLabels();
    this.chartColors = this.getDefaultChartColors();
    this.updateCharts(this.chartLabels, this.chartPercentages, this.chartColors);
    this.collapseFonds();
    this.recalculateClientProfileDataForViewOffer();
  }

  async switchIfEquilibree(): Promise<void> {
    if (this.activeGestionHorizonTab) {
      if (this.sereniteFondsExpanded) {
        this.sereniteFondsExpanded = false;
      }
      const montantVerse = this.clientProfile.initialPaymentAmount || 0; // initialPaymentAmount - it's one field for free payment amount and first payment amount
      const dateEstimationDepartRetraite = this.isPeri ? this.clientProfile.retirementPlan.expectedRetirementDate : null;
      const flagDurabilite = this.clientProfile.isQuestion12AnsweredYes(this.questions);
      const recommandationResponse = await this.wsReferentielMetierService.getRecommandation(
        60,
        !!this.clientProfile.transferContractCode,
        montantVerse,
        false,
        dateEstimationDepartRetraite,
        flagDurabilite
      );
      this.gestionHorizonMandatGestion = recommandationResponse.recommandation.produitRecommande.modeGestion.find((item: ModeGestionRecommandation) => {
        return item.libelle === constants.gestionHorizon.name;
      });
      this.selectedMandatGestion = new MandatGestion2({ ...this.gestionHorizonMandatGestion.mandatGestion });
      this.selectedMandatGestion.allocation = [];
      this.gestionHorizonMandatGestion.poche
        .map((item: Poche) => {
          return item.fondsRepartition;
        })
        .forEach((frs: FondsRepartition[]) => {
          frs?.forEach((fr: FondsRepartition) => {
            this.selectedMandatGestion.allocation.push(fr);
          });
        });
    } else {
      const allMondatGestions = await (await this.wsReferentielMetierService.getMandatGestion(constants.equilibré.id)).mandatGestion;
      allMondatGestions.forEach((item: MandatGestion2) => {
        if (item.id === constants.equilibré.id) {
          this.selectedMandatGestion = item;
        }
      });
    }

    this.selectedInvesmentProfile = constants.equilibré.id;
    this.profileTypeCode = constants.equilibré.id;
    this.selectedPlanName = constants.equilibré.name;
    this.chartPercentages = this.getChartPercentages();
    this.currentPlanInitialPercentages = this.getChartPercentages();
    this.chartLabels = this.getChartLabels();
    this.chartColors = this.getDefaultChartColors();
    this.updateCharts(this.chartLabels, this.chartPercentages, this.chartColors);
    this.collapseFonds();
    this.recalculateClientProfileDataForViewOffer();
  }

  async switchIfDynamique(): Promise<void> {
    if (this.activeGestionHorizonTab) {
      if (this.sereniteFondsExpanded) {
        this.sereniteFondsExpanded = false;
      }
      const montantVerse = this.clientProfile.initialPaymentAmount || 0; // initialPaymentAmount - it's one field for free payment amount and first payment amount
      const dateEstimationDepartRetraite = this.isPeri ? this.clientProfile.retirementPlan.expectedRetirementDate : null;
      const flagDurabilite = this.clientProfile.isQuestion12AnsweredYes(this.questions);
      const recommandationResponse = await this.wsReferentielMetierService.getRecommandation(
        90,
        !!this.clientProfile.transferContractCode,
        montantVerse,
        false,
        dateEstimationDepartRetraite,
        flagDurabilite
      );
      this.gestionHorizonMandatGestion = recommandationResponse.recommandation.produitRecommande.modeGestion.find((item: ModeGestionRecommandation) => {
        return item.libelle === constants.gestionHorizon.name;
      });
      this.selectedMandatGestion = new MandatGestion2({ ...this.gestionHorizonMandatGestion.mandatGestion });
      this.selectedMandatGestion.allocation = [];
      this.gestionHorizonMandatGestion.poche
        .map((item: Poche) => {
          return item.fondsRepartition;
        })
        .forEach((frs: FondsRepartition[]) => {
          frs?.forEach((fr: FondsRepartition) => {
            this.selectedMandatGestion.allocation.push(fr);
          });
        });
    } else {
      const allMondatGestions = await (await this.wsReferentielMetierService.getMandatGestion(constants.dynamique.id)).mandatGestion;
      allMondatGestions.forEach((item: MandatGestion2) => {
        if (item.id === constants.dynamique.id) {
          this.selectedMandatGestion = item;
        }
      });
    }

    this.selectedInvesmentProfile = constants.dynamique.id;
    this.profileTypeCode = constants.dynamique.id;
    this.selectedPlanName = constants.dynamique.name;
    this.chartPercentages = this.getChartPercentages();
    this.currentPlanInitialPercentages = this.getChartPercentages();
    this.chartLabels = this.getChartLabels();
    this.chartColors = this.getDefaultChartColors();
    this.updateCharts(this.chartLabels, this.chartPercentages, this.chartColors);
    this.collapseFonds();
    this.recalculateClientProfileDataForViewOffer();
  }

  async switchIfOffensive(): Promise<void> {
    const allMondatGestions = await (await this.wsReferentielMetierService.getMandatGestion(constants.offensif.id)).mandatGestion;
    allMondatGestions.forEach((item: MandatGestion2) => {
      if (item.id === constants.offensif.id) {
        this.selectedMandatGestion = item;
      }
    });
    this.selectedInvesmentProfile = constants.offensif.id;
    this.profileTypeCode = constants.offensif.id;
    this.selectedPlanName = constants.offensif.name;
    this.chartPercentages = this.getChartPercentages();
    this.currentPlanInitialPercentages = this.getChartPercentages();
    this.chartLabels = this.getChartLabels();
    this.chartColors = this.getDefaultChartColors();
    this.updateCharts(this.chartLabels, this.chartPercentages, this.chartColors);
    this.collapseFonds();
    this.recalculateClientProfileDataForViewOffer();
  }

  get isLessThanMinAmount(): boolean {
    this.amount = 0;

    if (this.activeGestionLibreTab) {
      return false;
    }

    if (this.clientProfile.initialPaymentAmount) {
      this.amount += this.clientProfile.initialPaymentAmount;
    }

    if (!this.isPeri) {
      if (this.clientProfile.transferContractCode && this.transferableContracts?.length > 0) {
        this.transferableContracts.forEach((item: ContratTransferableFourgousExtended) => {
          if (item.numeroContrat === this.clientProfile.transferContractCode) {
            this.amount += item.valeurRachat;
          }
        });
      }

      return this.amount < this.minAmountPerMode?.GSM;
    } else {
      // peri
      if (this.activeGestionLibreTab) {
        return this.amount < this.minAmountPerMode?.GL;
      }
      if (this.activeGestionSousMandatTab) {
        return this.amount < this.minAmountPerMode?.GSM;
      }
      if (this.activeGestionHorizonTab) {
        return this.amount < this.minAmountPerMode?.GH;
      } else return false;
    }
  }

  get minAmountPerModeValue(): number {
    if (this.activeGestionLibreTab) {
      return this.minAmountPerMode?.GL;
    }
    if (this.activeGestionSousMandatTab) {
      return this.minAmountPerMode?.GSM;
    }
    if (this.activeGestionHorizonTab) {
      return this.minAmountPerMode?.GH;
    }
    return 0;
  }

  get isPrudente(): boolean {
    return this.selectedMandatGestion?.libelle === constants.prudent.name;
  }

  get isEquilibree(): boolean {
    return this.selectedMandatGestion?.libelle === constants.equilibré.name;
  }

  get isDynamique(): boolean {
    return this.selectedMandatGestion?.libelle === constants.dynamique.name;
  }

  get isOffensive(): boolean {
    return this.selectedMandatGestion?.libelle === constants.offensif.name;
  }

  get isSecurityProfileRecommended(): boolean {
    return this.recommandation.profil?.libelle === constants.sécuritaire.name;
  }

  get submitRecommendationGHText(): string {
    return this.selectedMandatGestion?.libelle === this.recommandation.profil.libelle
      ? `${this.labelTextPipe.transform('Recommendation.submitRecommendationGHText1')} ’${this.selectedMandatGestion?.libelle}’`
      : `${this.labelTextPipe.transform('Recommendation.submitRecommendationGHText2')} ’${this.selectedMandatGestion?.libelle}’`;
  }

  get submitRecommendationGSMText(): string {
    return this.selectedMandatGestion?.libelle === this.recommandation.profil.libelle
      ? `${this.labelTextPipe.transform('Recommendation.submitRecommendationGSMText1')} ’${this.selectedMandatGestion?.libelle}’`
      : `${this.labelTextPipe.transform('Recommendation.submitRecommendationGSMText2')} ’${this.selectedMandatGestion?.libelle}’`;
  }

  periSecurityProfileBtnClick(): void {
    window.open(constants.links.periSecurityProfileBtnLink, '_blank');
  }

  getAllocations(managementTypeCode: number): FondsRepartition[] {
    let allocations: FondsRepartition[] = [];

    if (managementTypeCode === constants.gestionSousMandat.id || managementTypeCode === constants.gestionHorizon.id) {
      allocations = this.selectedMandatGestion?.allocation;
    } else {
      this.gestionLibre.poche
        .map((item: Poche) => {
          return item.fondsRepartition;
        })
        .forEach((frs: FondsRepartition[]) => {
          frs?.forEach((fr: FondsRepartition) => {
            allocations.push(fr);
          });
        });
    }

    return allocations;
  }

  async submitRecommendation(): Promise<void> {
    const isRecommendationsFollowed = this.selectedPlanName === this.profileTypeRecommended.plan;
    const allocations = this.getAllocations(this.managementTypeCode);
    const investmentProfile = {
      recommendedProfileId: this.recommandation.profil.id,
      selectedProfileId: this.selectedInvesmentProfile,
      managementTypeCode: this.managementTypeCode
    } as InvestmentProfile;

    await this.customerService.updateFondsModel(allocations, investmentProfile, this.profileTypeCode, isRecommendationsFollowed);

    this.router.navigate(['my-contract'], { queryParams: { userId: this.customerService.userId }, state: { mismatchCheck: true } });
  }

  goToMyProfile(): void {
    this.router.navigate(['my-profile'], { queryParams: { userId: this.customerService.userId } });
  }

  async notAcceptRecommendation(): Promise<void> {
    const isRecommendationsFollowed = false;
    const investmentProfile = {
      recommendedProfileId: this.recommandation.profil.id,
      selectedProfileId: this.activeGestionSousMandatTab ? this.selectedInvesmentProfile : null,
      managementTypeCode: this.managementTypeCode
    } as InvestmentProfile;

    const allocations = this.getAllocations(this.managementTypeCode);
    await this.customerService.updateFondsModel(allocations, investmentProfile, this.profileTypeCode, isRecommendationsFollowed);

    this.router.navigate(['my-contract'], { queryParams: { userId: this.customerService.userId }, state: { fromRecommendation: true } });
  }
}

export class ExpandedFond {
  id?: number;
  title: string;
  description?: string;
  file?: string;
  isEuro: boolean;
}

export class InvestmentProfile {
  recommendedProfileId?: number;
  selectedProfileId?: number;
  managementTypeCode: number;
}
