import { CheckboxFormControl } from '@form/checkbox-form-control/checkbox-form-control';
import { NumberFormControl } from '@form/number-form-control/number-form-control';
import { constants } from '@constants/constants';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';
import { FormGroup } from '@angular/forms';
import { collections } from '@constants/collections';
import { ComboBoxFormControl } from '@form/combobox-form-control/combobox-form-control';
import { CustomerService } from '@webapi/services/customer.service';
import { ContractTransfer, Payments, Profile } from '@webapi/MIF.Subscription.WebApi';
import { ContratTransferableFourgousExtended, WsPolicesService } from '@webapi/services/ws-polices.service';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { LabelsDataService } from '@services/labels-data.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DropDownFormControl } from '@form/dropdown-form-control/dropdown-form-control';
import { PaymentAmountLimits, WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { PaymentPlanModel } from '@extensions/user-data-extensions';
import { CurrencyPipe } from '@angular/common';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { SettingsService } from '@webapi/services/settings.service';
import { ProfileStepName } from '@models/profile-step-name';
import { AdermConfirmationComponent } from './modals/aderm-confirmation/aderm-confirmation.component';
import { isPeri } from '@extensions/extensions';
import { LabelTextPipe } from '@pipes/label-text.pipe';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyProfileComponent implements OnInit, OnDestroy {
  public isPeri = isPeri();
  paymentPlanForm: FormGroup;

  scheduledPayment = false;
  noScheduledPayment = false;
  scheduleChecked = false;
  faCheck = faCheck;

  extraResidenceQuestions = false;
  selected = '';
  freeInstallments = false;
  noFreeInstallments = false;
  checkedNonFree = false;

  installments = false;
  noInstallments = false;

  doTransferContractControl: ComboBoxFormControl;
  dofreePaymentControl: ComboBoxFormControl;
  contractControl: DropDownFormControl;
  freePaymentAmountControl: NumberFormControl;
  firstPaymentAmountControl: NumberFormControl;
  doScheduledPaymentControl: ComboBoxFormControl;

  constants: any = constants;
  userData: Profile;
  userDataForViewOffer: Profile;
  transferableContracts: ContratTransferableFourgousExtended[] = [];
  socialNumberB: string;
  dataRestored: boolean;
  selectedContractNumber: any;
  selectedContract: ContratTransferableFourgousExtended;
  freePaymentAmountLimits: PaymentAmountLimits;
  scheduledPaymentAmountLimits: PaymentAmountLimits;
  schedulePaymentAmountControl: NumberFormControl;
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  paymentsConfiguration: Payments;
  contractTransferConfiguration: ContractTransfer;
  ProfileStepName = ProfileStepName;

  scheduledPaymentAmountFromContract: any;
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private wsPolicesService: WsPolicesService,
    private labelsDataService: LabelsDataService,
    private bsModalService: BsModalService,
    private progressBarService: ProgressBarService,
    private wsReferentielMetierService: WsReferentielMetierService,
    private cp: CurrencyPipe,
    public settingsService: SettingsService,
    public labelTextPipe: LabelTextPipe
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.router.url === '/my-profile') {
      this.progressBarService.sendStep(0);
    }

    await this.fetchInitData();
    this.initNewFormGroup();
    this.initControls();
    await this.restoreAnswersOnForm();
  }

  async fetchInitData(): Promise<void> {
    this.settingsService.getDefaultProductTabsConfiguration();
    this.contractTransferConfiguration = await this.settingsService.getContractTransferConfiguration();
    this.userData = await this.customerService.getUserData();
    this.freePaymentAmountLimits = await this.wsReferentielMetierService.getFreePaymentAmountLimits();
    this.scheduledPaymentAmountLimits = await this.wsReferentielMetierService.getScheduledPaymentAmountLimits();
  }

  initNewFormGroup(): void {
    this.paymentPlanForm = new FormGroup({
      doTransferContract: new ComboBoxFormControl(false, false, {
        collection: collections.yesNo,
        valuePropertyName: 'description'
      }),
      contract: new DropDownFormControl(
        false,
        false,
        {
          valuePropertyName: 'libelleContratExtended',
          keyPropertyName: 'numeroContrat',
          collection: this.transferableContracts,
          disableChooseOption: false
        },
        {
          hideErrorMessages: true,
          hideErrorMark: false
        }
      ),
      doFreePayment: new ComboBoxFormControl(false, false, {
        collection: collections.yesNo,
        valuePropertyName: 'description'
      }),
      freePayment: new NumberFormControl(false, false, null, null, false, {
        min: parseFloat(this.freePaymentAmountLimits.minAmount.replace(',', '.')),
        max: parseFloat(this.freePaymentAmountLimits.maxAmount.replace(',', '.')),
        minValueErrorMessage: collections.messages.freePaymentMinError.replace('AMOUNT_VALUE', this.freePaymentAmountLimits.minAmount),
        maxValueErrorMessage: '',
        requiredErrorMessage: collections.messages.freePaymentMinError.replace('AMOUNT_VALUE', this.freePaymentAmountLimits.minAmount),
        placeholder: `${this.cp.transform(parseFloat(this.freePaymentAmountLimits.minAmount.replace(',', '.')), 'EUR', true)} minimum`
      }), // in case contracts exist
      firstPayment: new NumberFormControl(false, false, null, null, false, {
        min: parseFloat(this.freePaymentAmountLimits.minAmount.replace(',', '.')),
        max: parseFloat(this.freePaymentAmountLimits.maxAmount.replace(',', '.')),
        minValueErrorMessage: collections.messages.freePaymentMinError.replace('AMOUNT_VALUE', this.freePaymentAmountLimits.minAmount),
        maxValueErrorMessage: '',
        requiredErrorMessage: collections.messages.freePaymentMinError.replace('AMOUNT_VALUE', this.freePaymentAmountLimits.minAmount),
        placeholder: `${this.cp.transform(parseFloat(this.freePaymentAmountLimits.minAmount.replace(',', '.')), 'EUR', true)} minimum`
      }), // in case no contracts or do not transfer or no socialNumberB
      doScheduledPayment: new ComboBoxFormControl(true, false, {
        collection: collections.yesNo,
        valuePropertyName: 'description'
      }),
      schedulePayment: new NumberFormControl(false, false, null, null, false, {
        min: parseFloat(this.scheduledPaymentAmountLimits.minAmount.replace(',', '.')),
        max: parseFloat(this.scheduledPaymentAmountLimits.maxAmount.replace(',', '.')),
        minValueErrorMessage: collections.messages.scheduledPaymentMinError.replace('AMOUNT_VALUE', this.scheduledPaymentAmountLimits.minAmount.replace('.', ',')),
        maxValueErrorMessage: '',
        requiredErrorMessage: collections.messages.scheduledPaymentMinError.replace('AMOUNT_VALUE', this.scheduledPaymentAmountLimits.minAmount.replace('.', ',')),
        placeholder: `${this.cp.transform(parseFloat(this.scheduledPaymentAmountLimits.minAmount.replace(',', '.')), 'EUR', true)} minimum`
      }) // for all cases
    });

    if (this.isPeri) {
      this.paymentPlanForm.addControl('hasTaxDeduction', new CheckboxFormControl(false, false));
    }
  }

  async restoreAnswersOnForm(): Promise<any> {
    if (this.userData) {
      this.socialNumberB = this.userData.socialNumberB;

      if (this.socialNumberB) {
        this.transferableContracts = await this.wsPolicesService.getContratsTransferablesFourgousRequest(this.socialNumberB);

        if (!this.userData.transferContractCode) {
          this.firstPaymentAmountControl.markAsRequred();
        }

        if (this.transferableContracts && this.transferableContracts.length > 0) {
          this.dofreePaymentControl.markAsRequred();
          this.doTransferContractControl.markAsRequred();
          this.contractControl.setCollection(this.transferableContracts);

          if (this.userData.transferContractCode) {
            this.contractControl.markAsRequred();
          } else {
            // this.doTransferContractControl.patchValue(constants.noAnswer);
          }
        }
      } else {
        // case no socialNumberB
        if (this.userData.scheduledPaymentAmount) {
          this.firstPaymentAmountControl.markAsNotRequred();
        } else {
          this.firstPaymentAmountControl.markAsRequred();
        }
      }

      const paymentPlanModel = this.customerService.getPaymentPlanModel();
      const modelForUpdateForm = this.prepareRestoredPaymentPlanModel(paymentPlanModel);

      this.paymentPlanForm.patchValue(modelForUpdateForm);

      if (paymentPlanModel.transferContractCode) {
        this.transferContractChange({ value: paymentPlanModel.transferContractCode, restoreAnswersOnForm: true });
      }

      this.userDataForViewOffer = Object.assign(new Profile(), this.userData);
    }

    this.dataRestored = true;
  }

  prepareRestoredPaymentPlanModel(paymentPlanModel: PaymentPlanModel): any {
    const model: any = {};

    if (paymentPlanModel.hasTaxDeduction !== undefined && paymentPlanModel.hasTaxDeduction !== null) {
      // peri
      model.hasTaxDeduction = paymentPlanModel.hasTaxDeduction;
    }

    if (this.isTransferableContracts) {
      if (paymentPlanModel.transferContractCode) {
        model.doTransferContract = constants.yesAnswer;
        model.contract = paymentPlanModel.transferContractCode;
      } else if (paymentPlanModel.initialPaymentAmount || paymentPlanModel.scheduledPaymentAmount || paymentPlanModel.transferContractCode) {
        model.doTransferContract = constants.noAnswer;
      }

      this.doTransferContractChange(model.doTransferContract);

      if (paymentPlanModel.initialPaymentAmount) {
        model.doFreePayment = constants.yesAnswer;
        model.freePayment = paymentPlanModel.initialPaymentAmount;
      } else if (paymentPlanModel.initialPaymentAmount || paymentPlanModel.scheduledPaymentAmount || paymentPlanModel.transferContractCode) {
        model.doFreePayment = constants.noAnswer;
      }

      this.doFreePaymentChange(model.doFreePayment);
    }

    if (paymentPlanModel.scheduledPaymentAmount) {
      model.doScheduledPayment = constants.yesAnswer;
      model.schedulePayment = paymentPlanModel.scheduledPaymentAmount;
    } else if (paymentPlanModel.initialPaymentAmount || paymentPlanModel.scheduledPaymentAmount || paymentPlanModel.transferContractCode) {
      model.doScheduledPayment = constants.noAnswer;
    }

    this.doScheduledPaymentChange(model.doScheduledPayment);

    if (paymentPlanModel.initialPaymentAmount) {
      model.firstPayment = paymentPlanModel.initialPaymentAmount;
    }

    return model;
  }

  initControls(): void {
    this.doTransferContractControl = this.paymentPlanForm.get('doTransferContract') as ComboBoxFormControl;
    this.contractControl = this.paymentPlanForm.get('contract') as DropDownFormControl;
    this.dofreePaymentControl = this.paymentPlanForm.get('doFreePayment') as ComboBoxFormControl;
    this.freePaymentAmountControl = this.paymentPlanForm.get('freePayment') as NumberFormControl;
    this.firstPaymentAmountControl = this.paymentPlanForm.get('firstPayment') as NumberFormControl;
    this.doScheduledPaymentControl = this.paymentPlanForm.get('doScheduledPayment') as ComboBoxFormControl;
    this.schedulePaymentAmountControl = this.paymentPlanForm.get('schedulePayment') as NumberFormControl;

    this.subscriptionHandler.subscriptions = this.firstPaymentAmountControl.statusChanges.subscribe((status: string) => {
      if (status === constants.validStatus) {
        // this.doScheduledPaymentControl.markAsNotRequred();
      }
    });

    this.subscriptionHandler.subscriptions = this.paymentPlanForm.valueChanges.subscribe((data: any) => {
      const model = this.getPaymentsModel();
      this.userDataForViewOffer = Object.assign(new Profile(), this.customerService.updatePaymentPlanModelWithoutSaving(this.userData, model));
    });
  }

  get isShowDoTransfer(): boolean {
    if (this.isPeri) {
      return false;
    }

    const case1 = this.socialNumberB && this.isTransferableContracts && this.contractTransferConfiguration.authorizeFullPayment;
    const case2 = this.socialNumberB && this.isTransferableContracts && !!this.userData.transferContractCode;

    return case1 || case2;
  }

  get isDoTransfer(): boolean {
    return this.doTransferContractControl.value === constants.yesAnswer;
  }

  get isDoFreePayment(): boolean {
    return this.dofreePaymentControl.value === constants.yesAnswer;
  }

  get isDoScheduledPayment(): boolean {
    return this.doScheduledPaymentControl.value === constants.yesAnswer;
  }

  get isTransferableContracts(): boolean {
    return this.transferableContracts && this.transferableContracts.length > 0;
  }

  get isFirstPaymentHidden(): boolean {
    if (this.contractTransferConfiguration?.authorizeFullPayment === false) {
      return !!this.userData.transferContractCode && this.isDoTransfer;
    } else {
      return this.isTransferableContracts && this.doTransferContractControl.value === null && this.userData.isMember === true;
    }
  }

  showConditionsModal(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        body: this.labelsDataService.getData('details.contactsRedBoxLinkContent')
      },
      class: 'modal-lg'
    });
  }

  doTransferContractChange(event: any): void {
    if (event === constants.yesAnswer) {
      this.contractControl.markAsRequred();
      this.contractControl.patchValue(null);
      this.contractControl.markAsUntouched();

      this.doTransferContractControl.markAsRequred();

      this.dofreePaymentControl.markAsRequred();
      this.dofreePaymentControl.patchValue(null);
      this.dofreePaymentControl.markAsUntouched();

      this.doScheduledPaymentControl.markAsRequred();
      this.doScheduledPaymentControl.patchValue(null);
      this.doScheduledPaymentControl.markAsUntouched();

      this.transferContractChange({ value: this.contractControl.value });

      this.firstPaymentAmountControl.markAsNotRequred();
      this.firstPaymentAmountControl.patchValue(null);
    } else {
      this.contractControl.markAsNotRequred();
      this.doTransferContractControl.markAsNotRequred();

      this.dofreePaymentControl.markAsNotRequred();
      this.dofreePaymentControl.patchValue(null);

      this.freePaymentAmountControl.markAsNotRequred();
      this.freePaymentAmountControl.patchValue(null);
      this.freePaymentAmountControl.markAsUntouched();

      this.doScheduledPaymentControl.markAsRequred();
      this.doScheduledPaymentControl.patchValue(null);
      this.doScheduledPaymentControl.markAsUntouched();

      this.schedulePaymentAmountControl.patchValue(null);

      this.firstPaymentAmountControl.markAsRequred();
      this.firstPaymentAmountControl.markAsUntouched();
    }
  }

  doFreePaymentChange(event: any): void {
    if (event === constants.yesAnswer) {
      this.freePaymentAmountControl.markAsRequred();
    } else {
      this.freePaymentAmountControl.markAsNotRequred();
      this.freePaymentAmountControl.patchValue(null);
      this.freePaymentAmountControl.markAsUntouched();
    }
  }

  get getDoScheduledPaymentTitle(): string {
    let title = this.labelsDataService.getData('Common.DoYouWantToSchedulePayments');

    if (this.selectedContract && this.scheduledPaymentAmountFromContract) {
      title = this.labelsDataService.getData('Common.DoYouWantToKeepSchedulePayments');
    }

    return title;
  }

  doScheduledPaymentChange(event: any): void {
    if (event === constants.yesAnswer) {
      this.schedulePaymentAmountControl.markAsRequred();

      if (this.isTransferableContracts) {
        if (this.isDoTransfer) {
          this.doTransferContractControl.markAsNotRequred();
        }
        if (!this.isDoFreePayment) {
          this.dofreePaymentControl.markAsNotRequred();
        }
      } else {
        this.freePaymentAmountControl.markAsNotRequred();
      }

      this.firstPaymentAmountControl.markAsNotRequred();
    } else {
      this.schedulePaymentAmountControl.markAsNotRequred();
      this.schedulePaymentAmountControl.patchValue(null);
      this.schedulePaymentAmountControl.markAsUntouched();

      if (this.isTransferableContracts) {
        if (!this.isDoTransfer) {
          // no DoTransfer
          this.freePaymentAmountControl.markAsNotRequred();
          this.firstPaymentAmountControl.markAsRequred();
        }
      } else {
        // no TransferableContracts
        this.firstPaymentAmountControl.markAsRequred();
      }
    }
  }

  async transferContractChange(event: any): Promise<void> {
    this.selectedContractNumber = event.value;
    this.firstPaymentAmountControl.markAsNotRequred();

    this.selectedContract = this.transferableContracts?.filter((item: ContratTransferableFourgousExtended) => {
      return item.numeroContrat === this.selectedContractNumber;
    })[0];

    if (!event.value) {
      this.doScheduledPaymentControl.patchValue(null);
      this.doScheduledPaymentControl.markAsUntouched();
      this.schedulePaymentAmountControl.patchValue(null);
    }

    if (this.selectedContract && !event.restoreAnswersOnForm) {
      // only for a subscription flow, not for a data restoring
      //const polices = await this.wsPolicesService.getPolices2(640670); // 640670 for a test
      const polices = await this.wsPolicesService.getPolices2(this.selectedContractNumber);
      if (polices?.police && polices.police[0] && polices.police[0].cotisation) {
        this.scheduledPaymentAmountFromContract = polices.police[0].cotisation;

        if (this.scheduledPaymentAmountFromContract && typeof this.scheduledPaymentAmountFromContract === 'number' && this.scheduledPaymentAmountFromContract > 0) {
          this.doScheduledPaymentControl.patchValue(constants.yesAnswer);
          this.doScheduledPaymentChange(constants.yesAnswer);
          this.schedulePaymentAmountControl.patchValue(this.scheduledPaymentAmountFromContract);
          this.schedulePaymentAmountControl.markAsTouched();
        }
      } else {
        this.doScheduledPaymentControl.patchValue(null);
        this.doScheduledPaymentControl.markAsPristine();
        this.doScheduledPaymentControl.markAsUntouched();
        this.doScheduledPaymentChange(null);
        this.schedulePaymentAmountControl.patchValue(null);
      }

      if (!this.doScheduledPaymentControl.value || this.doScheduledPaymentControl.value === constants.noAnswer) {
        this.schedulePaymentAmountControl.markAsNotRequred();
      }

      if (!this.dofreePaymentControl.value || this.dofreePaymentControl.value === constants.noAnswer) {
        this.freePaymentAmountControl.markAsNotRequred();
      }
    }
    // } else {
    //   this.freePaymentAmountControl.markAsRequred();
    // }
  }

  getPaymentsModel(): PaymentPlanModel {
    const model = new PaymentPlanModel();

    if (this.isPeri) {
      model.hasTaxDeduction = this.paymentPlanForm.get('hasTaxDeduction').value;
    }

    if (this.paymentPlanForm.get('doTransferContract').value === constants.yesAnswer) {
      model.doTransferContract = true;
    } else if (this.paymentPlanForm.get('doTransferContract').value === constants.noAnswer) {
      model.doTransferContract = false;
    }

    if (this.isTransferableContracts) {
      if (this.doTransferContractControl.value === constants.yesAnswer) {
        model.transferContractCode = this.selectedContract?.numeroContrat;
        model.transferContractAmount = this.selectedContract?.valeurRachat;

        if (this.isDoFreePayment) {
          model.initialPaymentAmount = this.paymentPlanForm.get('freePayment').value;
        } else {
          model.initialPaymentAmount = null;
        }
      } else {
        model.transferContractCode = null;
        model.transferContractAmount = null;
        model.initialPaymentAmount = this.paymentPlanForm.get('firstPayment').value;
      }

      if (this.isDoScheduledPayment) {
        model.scheduledPaymentAmount = this.paymentPlanForm.get('schedulePayment').value;
      } else {
        model.scheduledPaymentAmount = null;
      }
    } else {
      // no transferable contracts
      model.transferContractCode = null;
      model.transferContractAmount = null;
      model.initialPaymentAmount = this.paymentPlanForm.get('firstPayment').value;

      if (this.isDoScheduledPayment) {
        model.scheduledPaymentAmount = this.paymentPlanForm.get('schedulePayment').value;
      } else {
        model.scheduledPaymentAmount = null;
      }
    }

    return model;
  }

  async submitPaymentPlan(): Promise<void> {
    const model = this.getPaymentsModel();
    const userId = await this.customerService.updatePaymentPlanModel(model);

    this.router.navigate(['/my-profile/steps'], { queryParams: { userId } });
  }

  async onTermsOfTransferClick(): Promise<void> {
    const fileBase64 = await this.wsReferentielMetierService.getCemsDocumentsProduit(constants.links.termsTransferOfContractDocId);
    const base64 = `data:application/pdf;base64,${fileBase64}`;
    const blob = await (await fetch(base64)).blob();
    const fileURL = URL.createObjectURL(blob);

    window.open(fileURL, '_blank');
  }

  submitPeri() {
    const modal = this.bsModalService.show(AdermConfirmationComponent, {
      initialState: {},
      class: 'modal-lg'
    });

    this.subscriptionHandler.subscriptions = modal.content.onContinue.subscribe(() => {
      this.submitPaymentPlan();
    });
  }

  get minAmountErrorBlockText(): string {
    let text = this.labelTextPipe.transform('Payments.ErrorBlockText');

    if (this.isPeri) {
      text = this.labelTextPipe.transform('Payments.ErrorBlockTextPeri');
    }

    return text;
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}
